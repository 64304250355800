import useEMR from "@/js/lib/emr-api";
import { existy } from "@/js/utils/existy";
import type {
  ApiResponse,
  Call,
  Permissions,
  Subscription,
} from "@/types/subscription";
import Bugsnag from "@bugsnag/js";
import dayjs from "dayjs";

type State = {
  isActiveSubscriber: boolean;
  subscription: Subscription | null;
  permissions: Permissions | null;
  call: Call;
  loading: boolean;
};

const DAYS_THRESHOLD = 10;

const state = reactive<State>({
  isActiveSubscriber: false,
  subscription: null,
  permissions: null,
  call: {},
  loading: false,
});

const daysLeftToMessage = computed(() => {
  if (!state.call?.end_time) return 0;

  const createdAt = dayjs(state.call?.end_time);
  const now = dayjs();
  const days = now.diff(createdAt, "day");

  return Math.max(DAYS_THRESHOLD - days, 0);
});

const isSubscriptionExpiring = computed(
  () => !!state.subscription?.expiring_at
);
const isSubscriptionExpired = computed(
  () => !Boolean(state.subscription?.expiring_at) && !state.isActiveSubscriber
);

export default function useSubscription() {
  const { EMRMembership: EMR } = useEMR();
  const { customer } = useTheme();
  const params = useUrlSearchParams("hash");

  const dataFetched = computed(
    () => existy(state.isActiveSubscriber) && existy(state.permissions)
  );

  async function getSubscriptionStatus() {
    if (!customer?.id) return;

    try {
      const { data } = await EMR.get<ApiResponse>(
        `/membership?shopify_customer_id=${customer.id}`
      );

      return data;
    } catch (error: any) {
      Bugsnag.notify(error);
    }
  }

  async function init() {
    if (state.loading || dataFetched.value) return;
    await fetchSubscriptionStatus();
  }

  async function fetchSubscriptionStatus() {
    state.loading = true;

    try {
      const result = await getSubscriptionStatus();
      state.isActiveSubscriber = result?.membership?.active || false;
      state.subscription = result?.membership?.subscription || null;
      state.permissions = result?.membership?.permissions || null;
      state.call = result?.membership?.call || {};

      if (params?.member === "true") {
        state.isActiveSubscriber = true;
        params.member = [];

        if (state.permissions) {
          state.permissions.book_a_vet_call = true;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      state.loading = false;
    }
  }

  async function toggleSubscriptionAutoRenew(type: "enable" | "disable") {
    try {
      state.loading = true;
      const { data } = await EMR.post<ApiResponse>(
        `/membership/renewal/${type}`
      );

      return data;
    } catch (error: any) {
      Bugsnag.notify(error);
    } finally {
      state.loading = false;
    }
  }

  init();

  return {
    ...toRefs(state),
    daysLeftToMessage,
    isSubscriptionExpiring,
    isSubscriptionExpired,
    toggleSubscriptionAutoRenew,
    fetchSubscriptionStatus,
  };
}
